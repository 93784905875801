/**
 * File for the `Top invoices` widget.
 */

import { Table } from 'antd';
import {
    clone,
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    orderBy,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { ATBStateOptions } from '../../constants/customersSortAndFilters';
import { populatePayloadForOrganisationRegionalWidgets } from '../../constants/dashboards';
import {
    dateFormatYYYYMMDDDash,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../../constants/dateFormats';
import {
    invoicesSortValues,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { AtbViewType, hiddenCloudImportFields } from '../../constants/settings';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardTopInvoicesRequestAction } from '../../store/dashboards/actions';
import { Invoice } from '../../store/invoices/types';
import {
    customFieldsTableColumnValues,
    dashboardBypassAPIFetch,
    formatDateToDateObjectUTC,
    getCompanyName,
    replaceInstancesOfCustomerString,
    getDateFilterValues,
    useCompanyFlagValue
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    invoiceFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';
import {
    displayColumnIndicator,
    displayColumnOptionsWithCalendarView,
    displayColumnOptionsWithDefaultView,
    displayRowCountOptions,
    hiddenTableColumns,
    showCustomFieldsIndicator,
} from './TopInvoicesWidgetFields';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}

const TopInvoicesWidget: React.FC<IProps> = ({
    widgetDetails,
    formatDateLocal,
    formatCurrency,
    customFieldsFilterList,
    isUsingCloudImportType,
    isOrgView,
    organisationCurrenciesAll,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);

    const usedDisplayColumns: any = isOrgView
        ? isCalendarView ? displayColumnOptionsWithCalendarView : displayColumnOptionsWithDefaultView
        : filter(isCalendarView ? displayColumnOptionsWithCalendarView : displayColumnOptionsWithDefaultView, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
    }));

    const dispatch = useDispatch();
    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
    });

    const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
    const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    /**
     * Common function for formatting currencies
     */
    const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const topInvoicesColumns: DynamicObject[] = [];
        const customFieldFilters: DynamicObject = {};
        const customFieldsDisplayList: any = [];
        let hasDisplayValue = false;
        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let Customer: string = '';
        let IsBlocked: any = undefined;
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let createdDateFilters: any = {
            value: undefined,
            From: undefined,
            To: undefined,
            Last: undefined,
            Next: undefined
        }
        let dueDateFilters: any = {
            value: undefined,
            From: undefined,
            To: undefined,
            Last: undefined,
            Next: undefined
        }
        let isDueDateRangeTypeLast: boolean = false;
        let CreatedDateMin: any = undefined;
        let CreatedDateMax: any = undefined;
        let DueDateMin: any = undefined;
        let DueDateMax: any = undefined;

        createdDateFilters.value = get(widgetDetails, "InvoiceField---CreatedDate--DateType");
        dueDateFilters.value = get(widgetDetails, "InvoiceField---DueDate--DateType");

        if (get(widgetDetails, "InvoiceField---DueDate--RangeType") === "Last") {
            isDueDateRangeTypeLast = true;
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue) &&
                !includes(hiddenTableColumns, fieldName)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        isCalendarView ? displayColumnOptionsWithCalendarView : displayColumnOptionsWithDefaultView,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    topInvoicesColumns.push({
                        title: replaceInstancesOfCustomerString(
                            columnTitle,
                            customerLabel,
                            isOrgView
                        ),
                        dataIndex: fieldName,
                    });
                }
            }

            if (includes(wdKey, customFieldIndicator)) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
            }

            if (
                includes(wdKey, showCustomFieldsIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const customFieldName = wdKey.replace(`${showCustomFieldsIndicator}${customFieldIndicator}`, '').split("--")[1];
                    const customFieldIndex = wdKey.replace(`${showCustomFieldsIndicator}`, '');
                    const customType = wdKey.replace(`${showCustomFieldsIndicator}${customFieldIndicator}`, '').split("--")[0];

                    const columnTitle: string = customFieldName;
                    topInvoicesColumns.push({
                        title: replaceInstancesOfCustomerString(
                            columnTitle,
                            customerLabel,
                            isOrgView
                        ),
                        dataIndex: customFieldIndex,
                    });

                    customFieldsDisplayList.push( {
                        type: customType,
                        FieldIndex: customFieldIndex
                    });
                }
            }

            if (wdKey === 'invoiceOpenState') {
                if (wdValue === ATBStateOptions[1].value) {
                    amountFilters = {
                        AmountOperator: '>',
                        AmountType: 'Total overdue',
                        AmountValue: 0,
                    };
                    const dateMaxWithTime = moment()
                        .subtract(1, 'day')
                        .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
                    DueDateMax = formatDateToDateObjectUTC(
                        dateMaxWithTime,
                        dateFormatYYYYMMDDTHHmmssDash
                    ).format(dateFormatYYYYMMDDTHHmmssDash);
                }
            }

            if (includes(wdKey, invoiceFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "Amount--AmountType")) {
                    amountFilters.AmountType = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountOperator")) {
                    amountFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountValue")) {
                    amountFilters.AmountValue = wdValue;
                }
                else if (includes(wdKey, "IsBlocked")) {
                    IsBlocked = wdValue;
                }
                else if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "CreatedDate")) {
                    if (createdDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            createdDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            createdDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (createdDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last")) {
                            createdDateFilters.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(createdDateFilters, true);
                    CreatedDateMin = minDate;
                    CreatedDateMax = maxDate;
                }
                else if (includes(wdKey, "DueDate")) {
                    if (dueDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            dueDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            dueDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (dueDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last") && isDueDateRangeTypeLast) {
                            dueDateFilters.Last = wdValue;
                        }
                        else if (includes(wdKey, "Next") && !isDueDateRangeTypeLast) {
                            dueDateFilters.Next = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(dueDateFilters, true);
                    DueDateMin = minDate;
                    DueDateMax = maxDate;
                }
            }
        });

        const invoiceCustomFieldsDataIndexes: string[] = [];
        const customerCustomFieldsDataIndexes: string[] = [];

        forEach(customFieldsDisplayList, (cfs: DynamicObject) => {
            const Type = get(cfs, 'type');
            const FieldName = get(cfs, 'FieldIndex');

            if (Type === CUSTOM_FIELD_TYPES.INVOICE) {
                invoiceCustomFieldsDataIndexes.push(FieldName);
            } else if (Type === CUSTOM_FIELD_TYPES.CUSTOMER) {
                customerCustomFieldsDataIndexes.push(FieldName);
            }
        });

        let payload: DynamicObject = {
            filters: {
                InvoiceNumber: '',
                Customer,
                CustomerCode: '',
                CreatedDateMin,
                CreatedDateMax,
                DueDateMin,
                DueDateMax,
                ...amountFilters,
                ...customFieldFilters,
                IsBlocked,
                CustomerCountry,
                CustomerState,
            },
            invoiceState: get(invoicesStateFilterOptions, '0.value'),
            sortBy: invoicesSortValues.AMOUNT_OWING,
            sortAscending: false,
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
            IsCalendarView: isCalendarView
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed,
            });
        }

        dispatch(
            getDashboardTopInvoicesRequestAction(
                payload,
                isOrgView,
                (invoices: Invoice[]) => {
                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = topInvoicesColumns;
                    } else {
                        usedColumns = tableState.columns;
                    }

                    if (isUsingCloudImportType) {
                        usedColumns = filter(
                            usedColumns,
                            (col) =>
                                !includes(
                                    hiddenCloudImportFields,
                                    col.dataIndex
                                )
                        );
                    }

                    const hasCustomInvoiceField = (filter(customFieldsDisplayList,
                        (item) => item.type  === "Invoice"
                      )).length;

                    const hasCustomCustomerField = (filter(customFieldsDisplayList,
                        (item) => item.type === "Customer"
                      )).length;

                    const usedDataSource = map(
                        invoices,
                        (inv: Invoice, invIdx: number) => {
                            let allCustomFieldsValues: DynamicObject = {};
                            if (
                                !isEmpty(inv.CustomFields) &&
                                hasCustomInvoiceField > 0
                            ) {
                                const invoiceCustomFields =
                                    customFieldsTableColumnValues(
                                        CUSTOM_FIELD_TYPES.INVOICE,
                                        inv.CustomFields,
                                        invoiceCustomFieldsDataIndexes
                                    );
                                allCustomFieldsValues = {
                                    ...invoiceCustomFields,
                                };
                            }
                            if (
                                !isEmpty(get(inv, 'Customer.CustomFields')) &&
                                hasCustomCustomerField > 0
                            ) {
                                const customerCustomFields =
                                    customFieldsTableColumnValues(
                                        CUSTOM_FIELD_TYPES.CUSTOMER,
                                        inv.Customer.CustomFields,
                                        customerCustomFieldsDataIndexes
                                    );
                                allCustomFieldsValues = {
                                    ...allCustomFieldsValues,
                                    ...customerCustomFields,
                                };
                            }

                            return {
                                key: invIdx,
                                ...inv,
                                CurrentFromCreate: !isUndefined(
                                    get(inv, 'AgedTrialBalanceFromCreate.Current')
                                )
                                    ? handleFormatCurrency(
                                        get(inv,'AgedTrialBalanceFromCreate.Current')
                                    )
                                    : '',
                                [isCalendarView ? 'FirstMonthFromCreate' : 'ThirtyDaysFromCreate']: !isUndefined(
                                    get(inv,'AgedTrialBalanceFromCreate.Period1')
                                )
                                    ? handleFormatCurrency(
                                        get(
                                            inv,'AgedTrialBalanceFromCreate.Period1'
                                        )
                                    )
                                    : '',
                            [isCalendarView ? 'SecondMonthFromCreate' : 'SixtyDaysFromCreate']: !isUndefined(
                                        get(inv, 'AgedTrialBalanceFromCreate.Period2')
                                    )
                                        ? handleFormatCurrency(
                                            get(inv, 
                                                'AgedTrialBalanceFromCreate.Period2')
                                        )
                                        : '',
                            [isCalendarView ? 'ThirdMonthFromCreate' : 'NinetyPlusDaysFromCreate']: !isUndefined(
                                        get(inv, 'AgedTrialBalanceFromCreate.Period3Plus')
                                    )
                                        ? handleFormatCurrency(
                                            get(inv, 
                                                'AgedTrialBalanceFromCreate.Period3Plus' 
                                                )
                                        )
                                        : '',
                            CurrentFromDue: !isUndefined(
                                    get(inv, 'AgedTrialBalanceFromDue.Current')
                                )
                                    ? handleFormatCurrency(
                                        get(inv,'AgedTrialBalanceFromDue.Current')
                                    )
                                    : '',
                            [isCalendarView ? 'FirstMonthFromDue' : 'ThirtyDaysFromDue']: !isUndefined(
                                get(inv, 'AgedTrialBalanceFromDue.Period1'  )
                            )
                                ? handleFormatCurrency(
                                    get(inv,'AgedTrialBalanceFromDue.Period1')
                                )
                                : '',
                            [isCalendarView ? 'SecondMonthFromDue' : 'SixtyDaysFromDue']: !isUndefined(
                                        get(inv, 'AgedTrialBalanceFromDue.Period2')
                                    )
                                        ? handleFormatCurrency(
                                            get(inv, 'AgedTrialBalanceFromDue.Period2')
                                        )
                                        : '',
                            [isCalendarView ? 'ThirdMonthFromDue' : 'NinetyPlusDaysFromDue']: !isUndefined(
                                        get(inv, 'AgedTrialBalanceFromDue.Period3Plus')
                                    )
                                        ? handleFormatCurrency(
                                            get(inv, 'AgedTrialBalanceFromDue.Period3Plus')
                                        )
                                        : '',
                            NotDue: !isUndefined(
                                get(inv, 'AgedTrialBalanceFromDue.NotDue')
                            )
                                ? handleFormatCurrency(
                                    get(
                                        inv,
                                        'AgedTrialBalanceFromDue.NotDue'
                                    )
                                )
                                : '',
                                LocalCreatedDate: get(inv, 'LocalCreatedDate')
                                    ? formatDateLocal(
                                        get(inv, 'LocalCreatedDate')
                                    )
                                    : '',
                                LocalDueDate: !isUndefined(
                                    get(inv, 'LocalDueDate')
                                )
                                    ? formatDateLocal(get(inv, 'LocalDueDate'))
                                    : '',
                                AmountOwing: !isUndefined(
                                    get(inv, 'AmountOwing')
                                )
                                    ? handleFormatCurrency(
                                        get(inv, 'AmountOwing')
                                    )
                                    : '',
                                OriginalAmount: get(inv, 'OriginalAmount')
                                    ? handleFormatCurrency(
                                        get(inv, 'OriginalAmount')
                                    )
                                    : '',
                                CustomerCode: get(inv, 'Customer.CustomerCode'),
                                CustomerName: getCompanyName(inv.Customer),
                                CompanyName: get(inv, 'Company.Name'),
                                ...allCustomFieldsValues,
                            };
                        }
                    );

                    updateTableStateObject({
                        columns: usedColumns,
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    };

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        if (isOrgView && isEmpty(organisationCurrenciesAll)) return;

        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails, organisationCurrenciesAll]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        columns: stateColumns,
        loading: stateLoading,
        dataSource: stateDataSource,
    } = tableState;
    return (
        <div>
            <Table
                className="table-striped-rows table-ws-nw"
                columns={stateColumns}
                dataSource={stateDataSource}
                loading={stateLoading}
                pagination={false}
                size="middle"
            />
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(TopInvoicesWidget))
);
