/**
 * Component drawer for Add widget panel.
 */

import { Button, Col, Icon, Input, List, Row } from 'antd';
import {
    clone,
    filter,
    get,
    includes,
    isEmpty,
    lowerCase,
    map,
    times,
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IsOrganisationViewAttribute } from '../../constants/authUserAttributes';
import {
    ORGANISATION_WIDGET_TYPES,
    widgetsDescription,
    WIDGET_TYPES,
    WIDGET_TYPE_COMPONENTS,
} from '../../constants/dashboards';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getCurrentUser } from '../../store/users/sagas';
import {
    debounceEventHandler,
    replaceInstancesOfCustomerString,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import { CompanyUserRole } from '../../store/companies/types';

interface IProps {
    visible: boolean;
    onDragWidget: Function;
    onAddWidget: Function;
}

const AddWidgetsPanel: React.FC<IProps> = ({
    visible,
    onDragWidget,
    onAddWidget,
}: IProps) => {
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    let supportCashAllocation = false;
    let supportPaymentBehaviourInsights = false;

    if (isOrgView) {
        for (const company of organisationCompanies) {
            if (!supportCashAllocation) {
                supportCashAllocation = get(
                    company,
                    'SupportCashAllocation'
                );
            }

            if (!supportPaymentBehaviourInsights) {
                supportPaymentBehaviourInsights = get(
                    company,
                    'EnablePaymentBehaviourInsights'
                );
            }
        }
    }
    else {
        supportCashAllocation = get(
            selectedUserCompany,
            'Company.SupportCashAllocation'
        );

        supportPaymentBehaviourInsights = get(
            selectedUserCompany,
            'Company.EnablePaymentBehaviourInsights'
        );
    }

    let widgetTypesUsed = isOrgView ? ORGANISATION_WIDGET_TYPES : WIDGET_TYPES;

    const customerLabel = useSelector(getCustomerUILabel);

    const drawerRef: any = useRef(null);

    let widgetsItemList = map(
        widgetTypesUsed,
        (widget: DynamicObject) => {
            const newWidget = clone(widget);
            newWidget.name = replaceInstancesOfCustomerString(
                widget.name,
                customerLabel,
                isOrgView
            );
            newWidget.title = replaceInstancesOfCustomerString(
                widget.title,
                customerLabel,
                isOrgView
            );

            return newWidget;
        }
    );

    //remove cash allocation if not supported
    widgetsItemList = widgetsItemList.filter(widget =>
        !(widget.supportCashAllocationOnly === true && !supportCashAllocation)
    );

    //remove payment behaviour insights if not supported
    widgetsItemList = widgetsItemList.filter(widget =>
        !(widget.supportPaymentBehaviourInsights === true && !supportPaymentBehaviourInsights)
    );

    const [selectedWidget, setSelectedWidget] = useState<
        DynamicObject | undefined
    >(undefined);

    const [widgetsList, setWidgetsList] = useState<any[]>(widgetsItemList);

    useEffect(() => {
        if (!visible) {
            setSelectedWidget(undefined);
        } else {
            setWidgetsList(widgetsItemList);
        }
    }, [visible]);

    /**
     * Function called when entering text in widget add panel search box.
     * @param e
     */
    const onWidgetsSearch = (e: any) => {
        const searchText = e.target.value;
        const filteredWidgetsList = filter(
            widgetsItemList,
            (widget: DynamicObject) => {
                widget.name = replaceInstancesOfCustomerString(
                    widget.name,
                    customerLabel,
                    isOrgView
                );
               return includes(lowerCase(widget.name), searchText)
            }
        );

        setWidgetsList(filteredWidgetsList);
    };

    /**
     * Function called when widget is selected.
     * @param widget
     */
    const selectWidget = (widget: DynamicObject) => {
        setSelectedWidget(widget);
    };

    if (visible) {
        return (
            <div className="add-widgets-panel" ref={drawerRef}>
                <Row>
                    <Col className="pl-15">
                        <h2>Add widget</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="pl-15">
                        <Input
                            onChange={debounceEventHandler(
                                onWidgetsSearch,
                                500
                            )}
                            placeholder="Search widgets"
                            allowClear
                            prefix={<Icon type="search" />}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="widgets-section"
                        style={{ height: window.innerHeight - 208 }}
                    >
                        <List
                            dataSource={widgetsList}
                            renderItem={(item) => {
                                const labelUsed = item.name;
                                const widgetDescription =
                                    replaceInstancesOfCustomerString(
                                        get(widgetsDescription, item.type, ''),
                                        customerLabel,
                                        isOrgView
                                    );
                                return (
                                    <List.Item
                                        key={item.type}
                                        className={
                                            get(selectedWidget, 'type') ===
                                                item.type
                                                ? 'selected-widget'
                                                : 'widget-item'
                                        }
                                        onClick={() => selectWidget(item)}
                                    >
                                        <div
                                            id={item.type}
                                            className="widget-item-container"
                                            draggable={true}
                                            unselectable="on"
                                            // this is a hack for firefox
                                            // Firefox requires some kind of initialization
                                            // which we can do by adding this attribute
                                            // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                                            onDragStart={(e) => {
                                                e.dataTransfer.setData(
                                                    'text/plain',
                                                    ''
                                                );

                                                let parentElementString = '';
                                                times(6, () => {
                                                    if (
                                                        parentElementString !==
                                                        ''
                                                    )
                                                        parentElementString +=
                                                            '.';
                                                    parentElementString +=
                                                        'parentElement';
                                                });
                                                const listContainerElement =
                                                    get(
                                                        e.target,
                                                        parentElementString
                                                    );
                                                if (listContainerElement)
                                                    listContainerElement.scrollTo(
                                                        0,
                                                        e.currentTarget
                                                            .offsetTop
                                                    );

                                                onDragWidget(item);
                                                selectWidget(item);
                                            }}
                                            // onDragEnd={(e) => {
                                            //     e.currentTarget.style.position =
                                            //         'initial';
                                            // }}
                                            // onDragLeave={(e) => {
                                            //     e.currentTarget.style.position =
                                            //         'initial';
                                            // }}
                                            style={{
                                                height: 150,
                                                width: '100%',
                                            }}
                                        >
                                            <div className="widget-item-row-container">
                                                <Row>
                                                    <Col span={6}>
                                                        <div className="widget-item-icon">
                                                            {get(
                                                                WIDGET_TYPE_COMPONENTS,
                                                                `${item.type}.icon`
                                                            )}
                                                        </div>
                                                    </Col>
                                                    <Col span={18}>
                                                        <div className="widget-item-name-description">
                                                            <h3>{labelUsed}</h3>
                                                            <p>
                                                                {
                                                                    widgetDescription
                                                                }
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-10">
                    <Col className="ta-right">
                        <Button
                            disabled={isEmpty(selectedWidget)}
                            type="primary"
                            onClick={() => {
                                onAddWidget(selectedWidget);
                            }}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    return null;
};

export default AddWidgetsPanel;
