import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    fork,
    put,
    takeLatest,
    delay,
    select,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import { appliedFilterIndicator } from '../../components/common/FilterBar';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    CUSTOMER_ASSISTANCE_PAGE,
    DETAILS_TAB,
} from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import { communicationResourceReplyType } from '../../constants/common';
import queries from '../../graphql/queries.graphql';
import {
    checkIfEmailIsValid,
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    getTicketDataErrorAction,
    getTicketDataInvoicesErrorAction,
    getTicketDataInvoicesSuccessAction,
    getTicketDataSuccessAction,
    getTicketDataTasksErrorAction,
    getTicketDataTasksSuccessAction,
    getTicketsErrorAction,
    getTicketsSuccessAction,
    setSelectedTicketIdSuccessAction,
    getTicketsRequestAction,
    getTicketConversationSuccessAction,
    getTicketConversationErrorAction,
    getTicketDiscussionSuccessAction,
    getTicketDiscussionErrorAction,
} from './actions';
import { GetTicketDiscussionRequestPayload, TicketsActionTypes } from './types';

/**
 * Selector for getting the details for a selected ticket.
 */
export const getTicketData = (state: ApplicationState) =>
    state.tickets.activeData;

/**
 * Selector for getting all the tickets data.
 */
export const getTicketsData = (state: ApplicationState) => state.tickets.data;

/**
 * Selector for getting the selectedId that was set upon clicking a ticket item
 * in Customer assistance page.
 */
export const getSelectedTicketId = (state: ApplicationState) =>
    state.tickets.activeData.selectedId;

export const getTicketsPageData = (state: ApplicationState) =>
    state.tickets.pageData;

/**
 * Function called to prepare the sort values to be passed to the API.
 * @param sortBy
 */
export const populateSortFieldsParamSortParams = (sortBy: string) => {
    const sortFields = { SortField: sortBy, ThenSortField: '' };

    return sortFields;
};

export const getTicketDiscussionPayload = (payload: any) => {
    const { record, currentPage } = payload as GetTicketDiscussionRequestPayload;
    const pageSize = DETAILS_TAB.CONVERSATION_TIMELINE.pageSize;
    const allTicketDiscussionFilter = {
        Type: [16],
        [`Type${appliedFilterIndicator}`]: [16],
    };
    const filters = {
        ResourceScopes: [{
            Resource: {
                Uri: record.Uri,
            },
            Scope: 'All',
        }], ...allTicketDiscussionFilter
    };

    const cleanFilters = removeAppliedFiltersForApiRequest(
        filters,
        true,
        undefined,
        true
    );

    const finalPayload = {
        ...cleanFilters,
        SortField: 'Created Date Time',
        Ascending: false,
        PageSize: pageSize,
        Skip: currentPage * pageSize,
    };

    return {
        finalPayload,
        pageSize,
        currentPage
    };
};

let refetchCount = 0;
/**
 * Function that fetches the tickets from API.
 * @param param0
 */
function* handleGetTicketsRequest({ payload }: any) {
    const errorMessage = 'Error fetching ticket list. Please try again later.';
    try {
        const {
            filters,
            //ticketState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
        } = payload;
        // To call async functions, use redux-saga's `call()`.
        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'ticket',
            true
        );
        const assignedUserValue = cleanFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete cleanFilters.AssignedUserId;
                cleanFilters.AssignedEmailAddress = assignedUserValue;
            }
        }
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TICKETS_FOR_COMPANY, {
                ...cleanFilters,
                //TicketState: ticketState,
                ...sortFields,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CUSTOMER_ASSISTANCE_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const { Tickets } = get(res.data, 'GetTicketsForCompany');

        if (Tickets) {
            const responsePayload = {
                data: Tickets,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Tickets.length < pageSize) &&
                        !(pageSize < CUSTOMER_ASSISTANCE_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTicketsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTicketsRequestAction(payload));
        } else {
            yield put(getTicketsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that fetches the ticket details based on the given ticketId.
 */
function* handleGetTicketDataRequest({
    payload: { ticketId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching ticket details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TICKET_DETAILS_FOR_COMPANY, {
                TicketId: ticketId,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const Ticket = get(res.data, 'GetTicketDetailsForCompany');

        if (Ticket) {
            const responsePayload = {
                record: Ticket,
            };

            yield put(getTicketDataSuccessAction(responsePayload));
        } else {
            yield put(getTicketDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTicketDataErrorAction([errorMessage]));
    }
}

/**
 * Function that fetches the ticket details for chat validation.
 */
function* handleGetTicketInfoForChatValidationRequest({
    payload: {
        callback,
        ...requestPayload
    }
}: any) {
    const errorMessage =
        'Error fetching ticket details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TICKET_INFO_FOR_CHAT_VALIDATION, requestPayload)
        );

        const Ticket = get(res.data, 'GetTicketInfoForChatValidation');

        if (Ticket) {
            const responsePayload = {
                Record: Ticket,
                IsSuccess: true,
                Messages: []
            };
            callback(responsePayload);
        } else {
            throw Error(errorMessage);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        callback({
            IsSuccess: false,
            Messages: [errorMessage]
        });
    }
}

/**
 * Function that sets the selectedId upon clicking the row item.
 * @param param0
 */
function* handleSetSelectedTicketIdRequest({ payload }: any) {
    const { ticketId, callback } = payload;
    yield put(setSelectedTicketIdSuccessAction(ticketId));
    callback();
}

/**
 * Ticket Data Invoices
 */
/**
 * Function that fetches the invoices for the selected ticket.
 * @param param0
 */
function* handleGetTicketDataInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching ticket's invoice list. Please try again later.`;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            invoiceState,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Invoices.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(getTicketDataInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTicketDataInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the tasks data for the selected ticket.
 * @param param0
 */
function* handleGetTicketDataTasksRequest({ payload }: any) {
    const errorMessage = `Error fetching ticket's tasks list. Please try again later.`;
    try {
        const {
            filters,
            // sortBy,
            // sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASKS_FOR_RESOURCE, {
                ...cleanFilters,
                // SortField: sortBy,
                // Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CUSTOMER_ASSISTANCE_PAGE.dataTasks.pageSize,
            })
        );

        const { Tasks } = get(res.data, 'GetTasksForResource');
        if (Tasks) {
            const responsePayload = {
                data: Tasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Tasks.length < pageSize) &&
                        !(
                            pageSize <
                            CUSTOMER_ASSISTANCE_PAGE.dataTasks.pageSize
                        ),
                },
            };

            yield put(getTicketDataTasksSuccessAction(responsePayload));
        } else {
            yield put(getTicketDataTasksErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTicketDataTasksErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the `Action` options on resolving ticket panel.
 * @param param0
 */
function* handleGetResolveTicketOptionsRequest({ payload: sagaPayload }: any) {
    const { workflowId, stateName, companyId, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TRIGGERS_FOR_WORKFLOW_STATE, {
                WorkflowId: workflowId,
                StateName: stateName,
                CompanyId: companyId
            })
        );

        const { WorkflowTransitions: triggerOptions } = get(
            res,
            'data.GetTriggersForWorkflowState'
        );

        if (callback) callback(triggerOptions);
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when resolving a ticket.
 * @param param0
 */
function* handleResolveQueryRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/ticket',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(`Ticket has been resolved successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for fetching the discussion list of a given ticket.
 * @param param0
 */
function* handleGetTicketDiscussionRequest({ payload }: any) {
    const errorMessage = `Error fetching ticket's discussion list. Please try again later.`;
    try {
        const {
            finalPayload,
            currentPage,
            pageSize
        } = getTicketDiscussionPayload(payload);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHAT_FOR_COMPANY, finalPayload)
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationResourcesForCompany'
        );

        if (ConversationLines) {
            const responsePayload = {
                data: ConversationLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage: ConversationLines.length >= pageSize
                }
            };

            yield put(getTicketDiscussionSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTicketDiscussionErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given ticket.
 * @param param0
 */
function* handleGetTicketConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching ticket's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getTicketConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTicketConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the ticket's conversation list.
 * @param param0
 */
function* handleTicketAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, ticketIds, excludeTickets, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'ticket'
    );

    const payload = {
        TicketManagementFilter: {
            ...cleanFilters,
            TicketIds: ticketIds,
            ExcludeTickets: excludeTickets,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/ticketcomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (ticketIds.length === 1 && excludeTickets === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const ticketsUpdated: DynamicObject[] = map(
                    ticketIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getTicketsPageData);
                yield put(
                    getTicketsSuccessAction({
                        data: ticketsUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function responsible for requesting resource reply.
 * @param param0
 */
function* handleTicketResourceReplyRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;
    const payload = {
        ...fields,
        ResourceType: communicationResourceReplyType.Ticket
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/communication/resource/reply', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// assign user
/**
 * Function called for assign user to tickets.
 * @param param0
 */
function* handleTicketAssignUserRequest({
    payload: sagaPayload,
}: any) {

    const { filter, ticketIds, excludeTickets, userObject, callback, companyId } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'ticket'
    );

    const payload = {
        TicketManagementFilter: {
            ...cleanFilters,
            TicketIds: ticketIds,
            ExcludeTickets: excludeTickets,
            CompanyId: companyId
        },
        ...userObject,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/ticket/action/assign-user',
            {
                body: payload,
            }
        );

        let successMessages: any = [];
        successMessages.push(
            `Ticket${res.Messages.length > 1 ? 's have' : ' has'
            } been assigned successfully!`
        );

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the ticket custom fields for a company.
 * @param param0
 */
function* handleGetTicketCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TICKET_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetTicketCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}


// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetTicketsRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKETS_REQUEST,
        handleGetTicketsRequest
    );
}

function* watchGetTicketDataRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_DATA_REQUEST,
        handleGetTicketDataRequest
    );
}

function* watchGetTicketInfoForChatValidationRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_INFO_FOR_CHAT_VALIDATION_REQUEST,
        handleGetTicketInfoForChatValidationRequest
    );
}

function* watchSetSelectedTicketIdRequest() {
    yield takeLatest(
        TicketsActionTypes.SET_TICKET_SELECTED_ID_REQUEST,
        handleSetSelectedTicketIdRequest
    );
}

// Task Data Invoices
function* watchGetTicketDataInvoicesRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_DATA_INVOICES_REQUEST,
        handleGetTicketDataInvoicesRequest
    );
}

// Notification Tickets
function* watchGetTicketDataTicketsRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_DATA_TASKS_REQUEST,
        handleGetTicketDataTasksRequest
    );
}

function* watchGetResolveTicketOptionsRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_RESOLVE_TICKET_OPTIONS_REQUEST,
        handleGetResolveTicketOptionsRequest
    );
}

function* watchResolveQueryRequest() {
    yield takeLatest(
        TicketsActionTypes.RESOLVE_QUERY_REQUEST,
        handleResolveQueryRequest
    );
}

function* watchGetTicketConversationRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_CONVERSATION_REQUEST,
        handleGetTicketConversationRequest
    );
}

function* watchGetTicketDiscussionRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_DISCUSSION_REQUEST,
        handleGetTicketDiscussionRequest
    );
}

function* watchTicketAddCommentRequest() {
    yield takeLatest(
        TicketsActionTypes.TICKET_ADD_COMMENT_REQUEST,
        handleTicketAddCommentRequest
    );
}

function* watchTicketResourceReplyRequest() {
    yield takeLatest(
        TicketsActionTypes.TICKET_RESOURCE_REPLY_REQUEST,
        handleTicketResourceReplyRequest
    );
}

function* watchTicketAssignUserRequest() {
    yield takeLatest(
        TicketsActionTypes.TICKET_ASSIGN_USER_REQUEST,
        handleTicketAssignUserRequest
    );
}

function* watchGetTicketCustomFieldsForCompanyRequest() {
    yield takeLatest(
        TicketsActionTypes.GET_TICKET_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetTicketCustomFieldsForCompanyRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* ticketsSaga() {
    yield all([
        fork(watchGetTicketsRequest),
        fork(watchGetTicketDataRequest),
        fork(watchGetTicketInfoForChatValidationRequest),
        fork(watchSetSelectedTicketIdRequest),
        fork(watchGetTicketDataInvoicesRequest),
        fork(watchGetTicketDataTicketsRequest),
        fork(watchGetResolveTicketOptionsRequest),
        fork(watchResolveQueryRequest),
        fork(watchGetTicketConversationRequest),
        fork(watchGetTicketDiscussionRequest),
        fork(watchTicketAddCommentRequest),
        fork(watchTicketResourceReplyRequest),
        fork(watchTicketAssignUserRequest),
        fork(watchGetTicketCustomFieldsForCompanyRequest)
    ]);
}

export default ticketsSaga;
